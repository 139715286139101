import { createStyles, Theme } from "@mui/material";

export const styles = (theme: Theme) =>
  createStyles({
    cropContainer: {
      position: "relative",
      width: "30vw",
      height: 400,
      background: "#333",
   
    },
    mobilecropContainer: {
      position: "relative",
      width: "100%",
      height: 300,
      background: "#333",
   
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
    },
    controls: {
      padding: 13,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
      },
    },
    sliderContainer: {
      display: "flex",
      flex: "1",
      alignItems: "center",
    },
    sliderLabel: {
      [theme.breakpoints.down("xs")]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: "18px 0px",
      marginLeft: 32,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        margin: "0 16px",
      },
    },
  });
