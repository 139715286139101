import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";

const urlRegex = RegExp(
  /^(http|https):\/\/(www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/
);
const personalMailProviders = ["fuse", "bellsouth", "aim", "mac", "excite", "verizon", "att", "live", "outlook", "aolmail", "blogger", "ymail", "rogers", "comcast", "37", "aol", "bigstring", "fastmail", "gawab", "gmail", "gmx", "hushmail", "inbox", "juno", "lycos", "mail", "msn", "muchomail", "runbox", "yahoo", "hotmail", "cox", "sbcglobal", "adelphia", "rr", "wp", "gmaill", "aoll", "husmail", "husmaill", "inboxl", "netzero", "junol", "lycosl", "msnl", "yahool", "hotmaill", "yaho"];

function getErrorsFromValidationError(validationError: any) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

function handleErrorMeg(msg: any, schema: any) {
  try {
    schema.validateSync(msg, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
}

const phoneNumberUtil = PhoneNumberUtil.getInstance();

// Custom Yup validation method for phone number validation
const phoneSchema = (isRequired: boolean) =>
  yup.string().test("phone", "Invalid phone number", function (value) {
    if (!value) return true; // Allow empty values
    if (value.length <= 3 && !isRequired) {
      return true;
    }
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  });

export let emailVerificationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email id")
    .required("Email id is required"),
  password: yup.string().required("Password is required"),
});

export function emailValidation(values: any) {
  return handleErrorMeg(values, emailVerificationSchema);
}

export let registerSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email ID")
    .required("Email ID is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  service: yup.string().required("Service is required"),
  service_status: yup.string().required("Service status is required"),
  rank: yup.string().required("Rank is required"),
  branch: yup.string().required("Branch is required"),
});

export function RegisterValidationSchema(values: any) {
  return handleErrorMeg(values, registerSchema);
}

function CheckisPersonalEmail(value: string | undefined) {
    if (!value) {
      return true;
    }
    const domain = value.split('@')[1];
    if (!domain) {
      return true;
    }
    const provider = domain.split('.')[0];
    if (!provider) {
      return true;
    }
    return !personalMailProviders.includes(provider);
}

export let registerEmployerSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  organization_name: yup.string().required("Organisation name is required"),
  email: yup
    .string()
    .email("Invalid Email ID")
    .test(
        'isPersonalEmail',
        'Please use an organisation email',
        CheckisPersonalEmail)
      .required("Organisation email ID is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  size: yup.number().required("Organisation size is required"),
});

export function RegisterEmployerValidationSchema(values: any) {
  return handleErrorMeg(values, registerEmployerSchema);
}

export let phoneValidationSchema = yup.object().shape({
  phone: phoneSchema(true).required("Mobile number is required"),
});

export function PhoneValidationSchema(values: any) {
  return handleErrorMeg(values, phoneValidationSchema);
}
export let CandidateLoginSchema = yup.object().shape({
  email: yup
      .string()
      .email("Invalid email ID")
      .required("Email ID is required"),
  password: yup.string().required("Password is required"),
});
export function CandidateLoginValidationSchema(values: any) {
  return handleErrorMeg(values, CandidateLoginSchema);
}
export let EmployerLoginSchema = yup.object().shape({
  email: yup
      .string()
      .email("Invalid email ID")
      .test(
          'isPersonalEmail',
          'Please use an organisation email',
          CheckisPersonalEmail)
      .required("Email ID is required"),
  password: yup.string().required("Password is required"),
});
export function EmployerLoginValidationSchema(values: any) {
  return handleErrorMeg(values, EmployerLoginSchema);
}
export let profileSchema = yup.object().shape({
  organization_name: yup.string().required("Organisation name is required"),
  industries: yup
    .array()
    .min(1, "Job Location should not be empty")
    .required("Job Location is required"),
});

export function ProfileValidationSchema(values: any) {
  return handleErrorMeg(values, profileSchema);
}

export let passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(15, "Password must not exceed 15 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one digit")
    .matches(
      /[!@#$%^&*()_]/,
      "Password must contain at least one special character (!@#$%^&*()_)"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export function PasswordSchema(values: any) {
  return handleErrorMeg(values, passwordSchema);
}
export let searchSchema = yup.object().shape({
  category: yup.string().nullable(),
  location: yup.string().nullable(),
  search: yup.string().nullable(),
});

export function SearchValidationSchema(values: any) {
  return handleErrorMeg(values, searchSchema);
}
export let otherInformationSchema = yup.object().shape({
  salary: yup.string().optional().nullable(),
  appointment_1: yup.string().optional().nullable(),
  appointment_2: yup.string().optional().nullable(),
  appointment_3: yup.string().optional().nullable(),
  preferred_location: yup.array().nullable(),
  preferred_department: yup.array().nullable(),
});

export function OtherInformationValidationSchema(values: any) {
  return handleErrorMeg(values, otherInformationSchema);
}

export let candidatesSearchSchema = yup.object().shape({
  department: yup.string().nullable(),
  location: yup.string().nullable(),
  search: yup.string().nullable(),
});

export function CandidatesSearchValidationSchema(values: any) {
  return handleErrorMeg(values, candidatesSearchSchema);
}

export let forgotPasswordEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email ID")
    .required("Email ID is required"),
});

export function handleForgotPasswordEmailValidation(values: any) {
  return handleErrorMeg(values, forgotPasswordEmailSchema);
}

export let subscriptionSchema = yup.object().shape({
  full_name: yup.string().required("Full name  is required"),
  organisation_name: yup.string().required("Organisation name is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  organisation_email: yup
    .string()
    .email("Invalid email id")
    .test(
        'isPersonalEmail',
        'Please use an organisation email',
        CheckisPersonalEmail)
    .required("Organisation Email ID is required"),
  number_of_hires: yup.string().required("Number of hiring is required"),
});
export function SubscriptionValidation(values: any) {
  return handleErrorMeg(values, subscriptionSchema);
}

export let raisequerySchema = yup.object().shape({
  enquiry_type: yup.string().required("Area of Concern  is required"),
  subject: yup.string().required("Subject  is required"),
  message: yup.string().required("Detail of Concern  is required"),
});
export function QueryValidation(values: any) {
  return handleErrorMeg(values, raisequerySchema);
}
export let contactusSchema = yup.object().shape({
  iam: yup.string().required("I am  is required"),
  name: yup.string().required("Name  is required"),
  organization_name: yup.string().when("iam", (iam: any, schema) => {
    
    if (iam[0] === "recruiter")
      return schema.required("Organisation name is required");

    return schema.optional().nullable();
  }),
  email: yup
    .string()
    .email("Invalid email ID")
    .required("Email ID is required"),
  mobile_number: phoneSchema(true).required("Mobile Number  is required"),
  message: yup.string().required("Message  is required"),
});
export function contactusValidation(values: any) {
  return handleErrorMeg(values, contactusSchema);
}

export let enquirySchema = yup.object().shape({
  full_name: yup.string().required("Name  is required"),
  email: yup
    .string()
    .email("Invalid email id")
    .required("Email ID is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  message: yup.string().required("Message  is required"),
});

export function EnquiryValidation(values: any) {
  return handleErrorMeg(values, enquirySchema);
}

export let personalInformationSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  size: yup.string().required("Organisation Size is required"),
  location: yup.string().required("Location is required"),
  address_1: yup.string().required("Address 1 is required"),
  address_2: yup.string().required("Address 2 is required"),
  pincode: yup.string().required("Pincode is required"),
  email: yup
    .string()
    .email("Invalid email ID")
    .required("Email ID is required"),
  benefits: yup
    .array()
    .min(1, "Benefits should not be empty")
    .required("Benefits is required"),
});

export function PersonalValidationSchema(values: any) {
  return handleErrorMeg(values, personalInformationSchema);
}
export let candidateProfileSchema = yup.object().shape({
  
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  gender: yup.object().required("Gender is required"),
  service_status: yup.object().required("Service status is required"),
});

export function CandidateProfileValidationSchema(values: any) {
  return handleErrorMeg(values, candidateProfileSchema);
}
export let profileInformationSchema = yup.object().shape({
  service: yup.object().required("Service is required"),
  rank: yup.object().required("rank is required"),
  branch: yup.object().required("Branch is required"),
  // military_id: yup.string().required("Military ID is required"),
  email: yup
    .string()
    .email("Invalid email ID")
    .required("Email ID is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  date_of_birth: yup.string().required("Date of Birth is required"),
  current_location: yup.object().required("Current location is required"),
  work_experience: yup.string().required("Experience is required"),
  willing_to_relocate: yup.string().required("Relocate is required"),
  joining_type: yup.object().required("Joining type is required"),
  disability: yup.string().required("specially abled is required"),
});

export function ProfileInformationValidationSchema(values: any) {
  return handleErrorMeg(values, profileInformationSchema);
}

export let socialMediaSchema = yup.object().shape({
  linkedin: yup
    .string()
    .matches(urlRegex, "Invalid url")
    .required("This field  is required"),
  facebook: yup
    .string()
    .matches(urlRegex, "Invalid url")
    .required("This field  is required"),
  instagram: yup
    .string()
    .matches(urlRegex, "Invalid url")
    .required("This field is required"),
});

export function SocialValidationSchema(values: any) {
  return handleErrorMeg(values, socialMediaSchema);
}
export let workExperienceSchema = yup.object().shape({
  employment_type: yup.string().required("Employment type is required"),
  company_name: yup.string().required("Company name  is required"),
  joined_at: yup.string().required("Joined year is required"),
  ended_at: yup.string().when("is_current", {
    is: false,
    then: (schema) =>
      schema
        .required("Ended year is required")
        .test(
          "end-date",
          "End year must be greater than joined year",
          function (value) {
            const joinedAt: any = this.resolve(yup.ref("joined_at"));
            if (!joinedAt || !value) return true; // Let required validation handle empty values
            return new Date(value) > new Date(joinedAt);
          }
        ),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  designation: yup.string().required("Designation is required"),
  is_current: yup.boolean(),
});

export function workExperienceValidationSchema(values: any) {
  return handleErrorMeg(values, workExperienceSchema);
}

export let aboutSchema = yup.object().shape({
  about: yup.string().required("About is required"),
});

export function AboutValidation(values: any) {
  return handleErrorMeg(values, aboutSchema);
}
export let educationSchema = yup.object().shape({
  degree: yup.string().required("Degree is required"),
  institution_name: yup.string().required("School/college is required"),
  academic_type: yup.string().required("Academic type is required"),
  joined_at: yup.string().required("Joining year is required"),
  ended_at: yup.string().required("End year is required"),
});

export function EducationValidation(values: any) {
  return handleErrorMeg(values, educationSchema);
}
export let certificateSchema = yup.object().shape({
  name: yup.string().required("Certificate name is required"),
  institution_name: yup.string().required("Issuing Authority  is required"),
  joined_at: yup.string().required("Joining year is required"),
  ended_at: yup.string().required("End year is required"),
});

export function CertificateValidation(values: any) {
  return handleErrorMeg(values, certificateSchema);
}
export let leadersSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last nameis required"),
  designation: yup.string().required("Designation is required"),
  linkedIn: yup
    .string()
    .matches(urlRegex, "Invalid url")
    .required("Link is required"),
  intro: yup.string().required("Introduction is required"),
});

export function leaderValidationSchema(values: any) {
  return handleErrorMeg(values, leadersSchema);
}

export let spotilightsSchema = yup.object().shape({
  video: yup.string().required("Video link is required"),
  heading: yup.string().required("Heading is required"),
  description: yup.string().required("Description is required"),
});

export function spotilightsValidationSchema(values: any) {
  return handleErrorMeg(values, spotilightsSchema);
}

export let postJobSchema = yup.object().shape({
  title: yup.string().required("Job Title is required"),
  opening: yup.string().required("Job Openings is required"),
  category: yup.object().required("Job Category is required"),
  salary_low: yup.string().required("Salary low is required"),
  salary_high: yup
    .string()
    .required("Salary high is required")
    .test(
      "is-greater",
      "Salary high must be greater than salary low",
      function (value) {
        const { salary_low } = this.parent;
        return parseInt(value) > parseInt(salary_low);
      }
    ),
  experience_low: yup.string().required("Experience low is required"),
  experience_high: yup
    .string()
    .required("Experience high is required")
    .test(
      "is-greater",
      "Experience high must be greater than Experience low",
      function (value) {
        const { experience_low } = this.parent;
        return parseInt(value) > parseInt(experience_low);
      }
    ),
  type: yup.object().required("Job Type high is required"),
  is_featured: yup.object().required("Is featured job is required"),
  job_location: yup
    .array()
    .min(1, "Job Location should not be empty")
    .required("Job Location is required"),
  target_date: yup.string().required("Apply before is required"),
  description: yup.string().required("Job Description is required"),
  responsibility: yup
    .string()
    .required("Roles and responsibilities is required"),
  qualification: yup.string().required("Required qualifications is required"),
  skills: yup
    .array()
    .min(1, "Skills should not be empty")
    .required("Skills is required"),
  benefits: yup
    .array()
    .min(1, "Benefits should not be empty")
    .required("Benefits is required"),
  has_resume: yup.boolean().required("Has resume is required"),
  has_cv: yup.boolean().required("Has cv is required"),
});

export function JobPostValidation(values: any) {
  return handleErrorMeg(values, postJobSchema);
}

export let changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required("Current password is required"),
  newPassword: yup.string().required("New password is required"),
  reEnterPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export function ChangePasswordValidation(values: any) {
  return handleErrorMeg(values, changePasswordSchema);
}
export let deleteAccountSchema = yup.object().shape({
  reason: yup.string().required("Reason is required"),
  feedback_content: yup.string().required("Feedback is required"),
});

export function DeleteAccountValidation(values: any) {
  return handleErrorMeg(values, deleteAccountSchema);
}
export let deactivateAccountSchema = yup.object().shape({
  reason: yup.string().required("Reason is required"),
  feedback_content: yup.string().required("Feedback is required"),
});

export function DeactivateAccountValidation(values: any) {
  return handleErrorMeg(values, deactivateAccountSchema);
}

export let feedbackShema = yup.object().shape({
  rating: yup.string().required("Rating is required"),
  comment: yup.string().required("Comment is required"),
  recommend: yup.string().required("Recommend is required"),
});

export function FeedbackValidation(values: any) {
  return handleErrorMeg(values, feedbackShema);
}