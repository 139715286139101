import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "asset/icons/facebook-white.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import { commonService } from "forms/helpers/common.service";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import { useEffect, useState } from "react";
import Loader from "ui-component/Loader";

export default function Footer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state?.user?.user);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    commonService
      .postServiceWithParams(`master/categories`, null, {
        page: 1,
        per_page: 1500,
        sort_order: "ASC",
        sort_by: "name",
      })
      .then((response) => {
        setIsLoading(false);
        const jobCategories = response?.data?.data?.results?.map(
          (category: any) => {
            return {
              name: category?.name,
              value: category?._id,
            };
          }
        );
        setCategories(jobCategories);
      })
      .catch((error) => {
        setIsLoading(false);
        Failed(
          errorMessage(error, "Something went wrong, Please try again later")
        );
      });
  };

  return (
    <>
      {isLoading && <Loader />}
      <Box
        component="footer"
        sx={{
          backgroundColor: theme?.palette?.primary?.backgroundGrey,
          px: { xs: "2%", md: "7.5rem" },
          mx: { xs: "-2%", md: "-7.5rem" },
          py: { xs: 3, md: 5 },
        }}
      >
        <Box display={{ xs: "none", sm: "block" }}>
          <Grid container>
            <Grid item sm={3}>
              <Typography
                variant="body2"
                color={theme?.palette?.primary?.textInverse}
                fontWeight={600}
              >
                Resources
              </Typography>
              <List>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary="Blogs"
                    onClick={() => {
                      navigate("/blogs");
                    }}
                    sx={{
                      "& .MuiListItemText-primary": {
                        color: theme?.palette?.primary?.textDisable,
                        fontSize: ".875rem",
                        fontWeight: 400,
                        cursor: "pointer",
                      },
                    }}
                  />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary="News and Events"
                    onClick={() => {
                      navigate("/news-and-events");
                    }}
                    sx={{
                      "& .MuiListItemText-primary": {
                        color: theme?.palette?.primary?.textDisable,
                        fontSize: ".875rem",
                        fontWeight: 400,
                        cursor: "pointer",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item sm={3}>
              <Typography
                variant="body2"
                color={theme?.palette?.primary?.textInverse}
                fontWeight={600}
              >
                Quick Links
              </Typography>
              <List>
                {user?.role !== "employer" && (
                  <>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="All Jobs"
                        onClick={() => {
                          navigate("/candidate/all-jobs");
                        }}
                        sx={{
                          "& .MuiListItemText-primary": {
                            color: theme?.palette?.primary?.textDisable,
                            fontSize: ".875rem",
                            fontWeight: 400,
                            cursor: "pointer",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="Explore Employer"
                        onClick={() => {
                          navigate("/candidate/explore-employers");
                        }}
                        sx={{
                          "& .MuiListItemText-primary": {
                            color: theme?.palette?.primary?.textDisable,
                            fontSize: ".875rem",
                            fontWeight: 400,
                            cursor: "pointer",
                          },
                        }}
                      />
                    </ListItem>
                  </>
                )}

                {user?.role !== "candidate" && (
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText
                      primary="Find Candidates"
                      onClick={() => {
                        navigate("/employer/find-candidate");
                      }}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: theme?.palette?.primary?.textDisable,
                          fontSize: ".875rem",
                          fontWeight: 400,
                          cursor: "pointer",
                        },
                      }}
                    />
                  </ListItem>
                )}

                {/* {(!user?.id ||
                  (user?.id &&
                    user?.role === "employer" &&
                    user?.is_primary)) && (
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText
                      primary="Product & pricing"
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: theme?.palette?.primary?.textDisable,
                          fontSize: ".875rem",
                          fontWeight: 400,
                          cursor: "pointer",
                        },
                      }}
                      onClick={() => {
                        navigate("/employer/subscription-plans");
                      }}
                    />
                  </ListItem>
                )} */}

                <ListItem
                  sx={{ p: 0, cursor: "pointer" }}
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  <ListItemText
                    primary="About Us"
                    sx={{
                      "& .MuiListItemText-primary": {
                        color: theme?.palette?.primary?.textDisable,
                        fontSize: ".875rem",
                        fontWeight: 400,
                        cursor: "pointer",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </Grid>
            {/* {user?.role !== "employer" && (
              <Grid item sm={12} mt={4}>
                <Typography
                  variant="body2"
                  color={theme?.palette?.primary?.textInverse}
                  fontWeight={600}
                >
                  Find Jobs by Category
                </Typography>
                <List>
                  <Grid container>
                    {categories?.map((category: any) => {
                      return (
                        <Grid item sm={3}>
                          <ListItem sx={{ p: 0 }}>
                            <ListItemText
                              primary={category?.name}
                              onClick={() => {
                                navigate("/candidate/all-jobs", {
                                  state: {
                                    category: category?.value,
                                  },
                                });
                              }}
                              sx={{
                                "& .MuiListItemText-primary": {
                                  color: theme?.palette?.primary?.textDisable,
                                  fontSize: ".875rem",
                                  fontWeight: 400,
                                  cursor: "pointer",
                                },
                              }}
                            />
                          </ListItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </List>
              </Grid>
            )} */}
          </Grid>
        </Box>
        <Box display={{ xs: "block", sm: "none" }}>
          <Accordion
            sx={{
              backgroundColor: theme?.palette?.primary?.backgroundGrey,
              color: theme?.palette?.primary?.textInverse,

              "& .MuiAccordionSummary-root": {
                p: 0,
              },
            }}
          >
            <AccordionSummary sx={{ fontWeight: 600 }}>
              Resources
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary="Blogs"
                    onClick={() => {
                      navigate("/blogs");
                    }}
                    sx={{
                      "& .MuiListItemText-primary": {
                        color: theme?.palette?.primary?.textDisable,
                        fontSize: ".875rem",
                        fontWeight: 400,
                        cursor: "pointer",
                      },
                    }}
                  />
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary="News and Events"
                    onClick={() => {
                      navigate("/news-and-events");
                    }}
                    sx={{
                      "& .MuiListItemText-primary": {
                        color: theme?.palette?.primary?.textDisable,
                        fontSize: ".875rem",
                        fontWeight: 400,
                        cursor: "pointer",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ opacity: 0.1 }}></Divider>
          <Accordion
            sx={{
              backgroundColor: theme?.palette?.primary?.backgroundGrey,
              color: theme?.palette?.primary?.textInverse,

              "& .MuiAccordionSummary-root": {
                p: 0,
              },
            }}
          >
            <AccordionSummary sx={{ fontWeight: 600 }}>
              Quick Links
            </AccordionSummary>
            <AccordionDetails>
              <List>
                {user?.role !== "employer" && (
                  <>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="All Jobs"
                        onClick={() => {
                          navigate("/candidate/all-jobs");
                        }}
                        sx={{
                          "& .MuiListItemText-primary": {
                            color: theme?.palette?.primary?.textDisable,
                            fontSize: ".875rem",
                            fontWeight: 400,
                            cursor: "pointer",
                          },
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="Explore Employer"
                        onClick={() => {
                          navigate("/candidate/explore-employers");
                        }}
                        sx={{
                          "& .MuiListItemText-primary": {
                            color: theme?.palette?.primary?.textDisable,
                            fontSize: ".875rem",
                            fontWeight: 400,
                            cursor: "pointer",
                          },
                        }}
                      />
                    </ListItem>
                  </>
                )}

                {user?.role !== "candidate" && (
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText
                      primary="Find Candidates"
                      onClick={() => {
                        navigate("/employer/find-candidate");
                      }}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: theme?.palette?.primary?.textDisable,
                          fontSize: ".875rem",
                          fontWeight: 400,
                          cursor: "pointer",
                        },
                      }}
                    />
                  </ListItem>
                )}

                {/* <ListItem sx={{ p: 0 }}>
                  <ListItemText
                    primary="Product & pricing"
                    sx={{
                      "& .MuiListItemText-primary": {
                        color: theme?.palette?.primary?.textDisable,
                        fontSize: ".875rem",
                        fontWeight: 400,
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      navigate("/employer/subscription-plans");
                    }}
                  />
                </ListItem> */}
                <ListItem
                  sx={{ p: 0, cursor: "pointer" }}
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  <ListItemText
                    primary="About Us"
                    sx={{
                      "& .MuiListItemText-primary": {
                        color: theme?.palette?.primary?.textDisable,
                        fontSize: ".875rem",
                        fontWeight: 400,
                        cursor: "pointer",
                      },
                    }}
                  />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Divider sx={{ opacity: 0.1 }}></Divider>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              fontSize: ".75rem",
              display: "flex",
            }}
          >
            <Link
              sx={{
                fontWeight: "400 !important",
                textDecoration: "none",
                color: `${theme?.palette?.primary?.textInverse} !important`,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/terms-and-conditions");
              }}
            >
              Terms and Conditions
            </Link>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 1, opacity: ".2" }}
            />
            <Link
              sx={{
                fontWeight: "400 !important",
                textDecoration: "none",
                color: `${theme?.palette?.primary?.textInverse} !important`,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </Link>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 1, opacity: ".2" }}
            />
            <Link
              sx={{
                fontWeight: "400 !important",
                textDecoration: "none",
                color: `${theme?.palette?.primary?.textInverse} !important`,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/refund-and-cancellation");
              }}
            >
              Refund and Cancellation
            </Link>
          </Box>
          <Box
            sx={{
              color: theme?.palette?.primary?.textInverse,
              fontWeight: 400,
              fontSize: ".75rem",
              display: "flex",
            }}
          >
            &#169;2024
            <Divider
              orientation="vertical"
              flexItem
              sx={{ mx: 1, opacity: ".2" }}
            />
            All Rights Reserved
          </Box>
        </Box>
      </Box>
    </>
  );
}
