import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, Toolbar, useMediaQuery } from "@mui/material";

// project imports
import ProfileSection from "./ProfileSection";

// assets
import { IconMenu2 } from "@tabler/icons";
import LogoSection from "../LogoSection";
import Logo from "ui-component/Logo";
import { useAppSelector } from "store/store";
import NavItem from "./NavItem/index";
import { useLocation } from "react-router-dom";
import NotificationSection from "./notification/fht-notification-dialog/index";
import { useDispatch } from "react-redux";
import { setMenuItemsRedux } from "store/slice/user.slice";
import { useEffect } from "react";
import Menus from "layout/menu-items/menu";
import CandidateMenus from "layout/menu-items/candidate-menu";
import EmployerMenus from "layout/menu-items/employer-menu";
import PersonIcon from "@mui/icons-material/Person";
import AccountIcon from "asset/images/Account.svg";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }: HeaderTypes) => {
  const location = useLocation();

  const theme = useTheme();
  const dispatch = useDispatch();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const menuItems: any = useAppSelector((state) => state.user.menuItems);
  const auth = useAppSelector((state) => state.user.user);

  const setMenu = () => {
    let menus: any = [];
    menus = Menus;
    dispatch(setMenuItemsRedux(menus));
  };
  useEffect(() => {
    if (Object.keys(auth).length) {
      if (auth.role === "candidate") {
        let menus: any = [];
        menus = CandidateMenus;
        dispatch(setMenuItemsRedux(menus));
      } else {
        let menus: any = [];
        menus = EmployerMenus;
        dispatch(setMenuItemsRedux(menus));
      }
    } else {
      setMenu();
    }
  }, []);

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  let path: any;

  if (pathSegments[1] === "services_menu") {
    path = pathSegments[1];
  } else if (pathSegments[2] === "verification") {
    path = pathSegments[2];
  } else if (pathSegments[2] === "mobile_user_verification") {
    path = pathSegments[2];
  }

  if (
    pathSegments[0] === "profile_edit" &&
    menuItems[0]?.children[0]?.title === "Verification"
  ) {
    path = pathSegments[0];
  }

  return (
    <>
      <Toolbar
        sx={{
          p: "0!important",
          mx: { md: "7.5rem", xs: "2%" },
          justifyContent: matchDownMd ? "space-between" : "flex-start",
        }}
      >
        {/* logo & toggler button */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box display={"flex"}>
            {matchDownMd && (
              <ButtonBase
                disableRipple
                sx={{ borderRadius: "12px", overflow: "hidden" }}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    transition: "all .2s ease-in-out",
                    background: "transparent",
                    color: theme.palette.primary?.iconGreyDark,
                    "&:hover": {
                      background: "transparent",
                      color: theme.palette.primary?.iconGreyDark,
                    },
                  }}
                  onClick={handleLeftDrawerToggle}
                  color="inherit"
                >
                  <IconMenu2 stroke={1.5} size="1.8rem" />
                </Avatar>
              </ButtonBase>
            )}
            <Box sx={{ pb: ".35rem" }}>
              <Logo width={matchDownMd ? "80" : "140"} />
            </Box>
          </Box>

          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            {menuItems[0]?.appName == "FHT" && (
              <NotificationSection socket={""} />
            )}
            <ProfileSection />
          </Box>
        </Box>
      </Toolbar>

      <>
        {!matchDownMd && (
          <Toolbar
            sx={{
              p: 0,
              backgroundColor: theme.palette.primary?.backgroundDarkGrey,
              marginTop: "-3px",
            }}
          >
            <Box
              sx={{
                mx: { sm: "5%", xs: "2%" },
                py: { md: 0, xs: 0 },
                display: "flex",
                justifyContent: "center",
                width: "100%",
                [theme.breakpoints.down("md")]: {
                  width: "auto",
                  flexDirection: "row-reverse",
                },
              }}
            >
              {!matchDownMd ? (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  {menuItems[0]?.children?.map((item: any) => (
                    <NavItem item={item} isMainMenu={false} icon={item?.icon} />
                  ))}
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </Toolbar>
        )}
      </>
    </>
  );
};

type HeaderTypes = {
  handleLeftDrawerToggle: () => void;
};

export default Header;
