import { forwardRef, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useLocation } from "react-router-dom";
import Assets from "helpers/assets.helper";
import { useAppDispatch, useAppSelector } from "store/store";
import { setSubMenuItemsRedux } from "store/slice/user.slice";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, icon }: NavItemTypes) => {
  // active menu item on page load
  const location = useLocation();
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const auth = useAppSelector((state) => state.user.user);
  const handleClick = (event: any) => {
    if (item?.url) {
      navigate(item?.url);
    }
  };
  const handleSubmenuClick = (event: any) => {
    if (item?.children) {
      setAnchorEl(event.currentTarget);
    }
  };

  const { job_id, id } = useParams();
  const targetId = id || job_id;

  const pathname = targetId
    ? location.pathname.replace(`/${targetId}`, "")
    : location.pathname;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (event: any) => {
    if (item?.title === "Candidate") {
      setDropdown(true);
    }
  };

  return (
    <>
      <MenuItem
        onClick={handleClick}
        className={"sub-menu"}
        id="basic-button"
        selected={
          item?.activeItem && item?.activeItem.length
            ? item?.activeItem?.includes(pathname)
            : `/${item.url}` === pathname
        }
        sx={{ height: "47px" }}
      >
        <ListItemText
          sx={{ marginLeft: "0rem" }}
          primary={
            <Typography
              sx={{
                fontWeight: 600,
                opacity: item?.activeItem?.includes(pathname) ? "100%" : "70%",
              }}
              variant={"h5"}
              color="inherit"
            >
              {item?.title}
            </Typography>
          }
        />
        <Box
          onClick={($e: any) => {
            $e.stopPropagation();
            handleSubmenuClick($e);
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            ml: 2,
            opacity: item?.activeItem?.includes(pathname) ? "100%" : "70%",
          }}
        >
          {item?.children && <KeyboardArrowDownIcon />}
        </Box>
      </MenuItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          mt: 2,
          "& .MuiPaper-root": {
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", // Remove box shadow
          },
        }}
      >
        {item?.children?.map((child: any) => {
          return (
            <MenuItem
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  navigate(child?.url);
                }, 300);
              }}
              sx={{ mb: 2 }}
            >
              <ListItemIcon>
                <img src={child?.icon} alt="jobs" />
              </ListItemIcon>
              <ListItemText>{child?.title}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

type NavItemTypes = {
  item: any;
  isMainMenu: boolean;
  icon?: any;
};

export default NavItem;
