import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ExperienceIcon from "asset/icons/experience.svg";
import LocationIcon from "asset/icons/location.svg";
import ClockIcon from "asset/icons/clock.svg";
import RupeeIcon from "asset/icons/rupee.svg";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, useSetState } from "forms/hooks/customHook";
import dayjs from "dayjs";
import { useAppSelector } from "store/store";
import { useNavigate } from "react-router-dom";
import { commonService } from "forms/helpers/common.service";
import { Failed } from "forms/helpers/toast.helper";
import { errorMessage } from "forms/helpers/function.helper";
import NoCompanyImage from "asset/images/no-company.svg";
import parse from "html-react-parser";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import StarImage from "asset/icons/star.svg";

const JobCard = ({
  isHomeFeatured,
  job,
  isFullWidth,
  isMyJob,
  isActive,
  isAppliedJob,
  isSavedJob,
  hasLocationChip,
  branding,
  appliedAt,
  handleJobClick,
  handleJobDelete,
  handleJobEdit,
  handleJobArchive,
  handleJobSave,
}: {
  isHomeFeatured?: boolean;
  job?: any;
  isFullWidth?: boolean;
  isActive?: boolean;
  isMyJob?: boolean;
  isAppliedJob?: boolean;
  isSavedJob?: boolean;
  hasLocationChip?: boolean;
  branding?: string;
  appliedAt?: string;
  handleJobClick?: () => void;
  handleJobDelete?: (id: string) => void;
  handleJobEdit?: (id: string) => void;
  handleJobArchive?: (id: string) => void;
  handleJobSave?: () => void;
}) => {
  const theme = useTheme();
  const [skills, setSkills] = useSetState("");
  const user = useAppSelector((state) => state?.user?.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [saveJob, setSaveJob] = useState(false);
  const [showSaveTooltip, setShowSaveTooltip] = useState(false);
  const [anchorJobEl, setAnchorJobEl] = useState<null | HTMLElement>(null);
  const openJobMenu = Boolean(anchorJobEl);
  const [statusStyle, setStatusStyle] = useState({
    background: "#F0F4FA",
    color: "#15171E",
  });
  const rooms = useAppSelector((state) => state?.user?.rooms);
  useEffect(() => {
    const jobSkills = job?.skills?.map((skill: any) => skill.name).join(",");

    if (isAppliedJob) {
      getStatusStyle();
    }
  }, []);

  const getStatusStyle = () => {
    switch (job?.application_status) {
      case "applied":
        setStatusStyle({
          background: "#F0F4FA",
          color: "#15171E",
        });
        break;
      case "rejected":
        setStatusStyle({
          background: "#FFEEEE",
          color: "#F40000",
        });
        break;
      case "shortlisted":
        setStatusStyle({
          background: "#E4F3FF",
          color: "#2F80ED",
        });
        break;
      case "screened":
        setStatusStyle({
          background: "#ffff8d",
          color: "#ffab00",
        });
        break;
      case "hired":
        setStatusStyle({
          background: "#E4FFFE",
          color: "#00B066",
        });
        break;
      case "interviewed":
        setStatusStyle({
          background: "#e1bee7",
          color: "#6a1b9a",
        });
        break;
      default:
        setStatusStyle({
          background: "#F0F4FA",
          color: "#15171E",
        });
        return;
    }
  };

  const handleSaveJob = () => {
    setIsLoading(true);
    const payload = {
      jobId: job._id,
    };
    commonService
      .putService("/candidate/saved-jobs", payload)
      .then((res) => {
        setIsLoading(false);
        setSaveJob(true);
        setShowSaveTooltip(true);
        if (handleJobSave) {
          handleJobSave();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        Failed(
          errorMessage(err, "Something went wrong, Please try again later")
        );
      });
  };

  const handleJobMenuClose = () => {
    setAnchorJobEl(null);
  };
  return (
    <Box
      onClick={handleJobClick}
      sx={{
        border: isActive
          ? `3px solid ${
              branding ? branding : theme?.palette?.primary?.buttonOrange
            }`
          : `1px solid ${
              branding ? branding : theme?.palette?.primary?.borderLinelight
            }`,
        boxShadow: isActive ? "0px 8px 32px 0px #33414D1A" : "none",
        borderRadius: "8px",
        p: 2,
        cursor: "pointer",
        "&:hover": {
          border: isActive
            ? `3px solid ${
                branding ? branding : theme?.palette?.primary?.buttonOrange
              }`
            : `1px solid ${
                branding ? branding : theme?.palette?.primary?.buttonOrange
              }`,
        },
      }}
    >
      <Stack
        direction={{ xs: isAppliedJob ? "column" : "row", sm: "row" }}
        justifyContent={"space-between"}
        mb={1}
      >
        <Box display={"flex"} gap={2}>
          {job?.employer_id?.profile ? (
            <Box
              component={"img"}
              src={job?.employer_id?.profile}
              height={50}
              width={50}
              borderRadius={"5px"}
              onClick={($e: any) => {
                $e.stopPropagation();
                navigate(
                  `/candidate/employer-details/${job?.employer_id?._id}`
                );
              }}
            />
          ) : (
            <Box
              component={"img"}
              src={NoCompanyImage}
              height={50}
              width={50}
            />
          )}

          <Box>
            <Typography
              sx={{
                maxWidth: "200px",
                overflow: "hidden",
                display: "inline-block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="h4"
              title={job?.title}
            >
              {job?.title}
            </Typography>
            <Typography
              variant="body2"
              color={theme?.palette?.primary?.textMid}
              fontWeight={500}
              onClick={($e: any) => {
                if (!isMyJob) {
                  $e.stopPropagation();
                  navigate(
                    `/candidate/employer-details/${job?.employer_id?._id}`
                  );
                }
              }}
            >
              {job?.employer_id?.organization_name}
            </Typography>
          </Box>
        </Box>

        {isAppliedJob ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "end",
            }}
          >
            <Box display={"flex"} justifyContent="center">
              <Chip
                label={capitalizeFirstLetter(job?.application_status)}
                sx={{
                  background: statusStyle?.background,
                  borderRadius: "32px",
                  minWidth: "8rem",
                  color: statusStyle?.color,
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                sx={{ fontWeight: 400, color: theme?.palette.primary.textGrey }}
              >
                {job?.application_status === "applied" ? "Applied" : "Updated"}
              </Typography>
              <Typography
                sx={{ fontWeight: 400, color: theme?.palette.primary.textBtn }}
              >
                <b>{appliedAt && getPostedDateTime(appliedAt, "applied")}</b>
              </Typography>
            </Box>
            {job?.is_featured && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: theme.palette.primary.chipyellow,
                  borderRadius: "12px",
                  padding: "4px 8px",
                  gap: 0.75,
                }}
              >
                <img src={StarImage} alt={"StarImage"} />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#4A5568",
                    fontSize: "0.875rem",
                  }}
                >
                  Featured
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            {!isMyJob && !isSavedJob && (
              <Tooltip
                title={saveJob && showSaveTooltip && "Job saved successfully"}
                placement="top-start"
              >
                {job?.has_saved || saveJob ? (
                  <Box
                    sx={{ cursor: "pointer" }}
                    height={"1.5rem"}
                    onMouseLeave={() => {
                      setShowSaveTooltip(false);
                    }}
                  >
                    <svg
                      width="22"
                      height="26"
                      viewBox="0 0 22 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill={
                          branding
                            ? branding
                            : theme?.palette?.primary?.iconOrange
                        }
                        d="M1.66699 7.4C1.66699 5.15979 1.66699 4.03969 2.10297 3.18404C2.48646 2.43139 3.09838 1.81947 3.85103 1.43597C4.70668 1 5.82678 1 8.06699 1H13.9337C16.1739 1 17.294 1 18.1496 1.43597C18.9023 1.81947 19.5142 2.43139 19.8977 3.18404C20.3337 4.03969 20.3337 5.15979 20.3337 7.4V25L11.0003 19.6667L1.66699 25V7.4Z"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                ) : (
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={($e: any) => {
                      $e.stopPropagation();
                      if (user?.id) {
                        handleSaveJob();
                      } else {
                        localStorage.setItem("action-job", job?._id);
                        navigate("/candidate/login");
                      }
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke={
                          branding
                            ? branding
                            : theme?.palette?.primary?.iconOrange
                        }
                        d="M5 7.8C5 6.11984 5 5.27976 5.32698 4.63803C5.6146 4.07354 6.07354 3.6146 6.63803 3.32698C7.27976 3 8.11984 3 9.8 3H14.2C15.8802 3 16.7202 3 17.362 3.32698C17.9265 3.6146 18.3854 4.07354 18.673 4.63803C19 5.27976 19 6.11984 19 7.8V21L12 17L5 21V7.8Z"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                )}
              </Tooltip>
            )}
            {isMyJob && job?.application_status !== "archived" && (
              <IconButton
                size="large"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                  setAnchorJobEl(event.currentTarget);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
            <Menu
              id="job-menu"
              anchorEl={anchorJobEl}
              open={openJobMenu}
              onClose={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                setAnchorJobEl(null);
              }}
              MenuListProps={{
                "aria-labelledby": "job-menu-button",
              }}
            >
              <MenuItem
                onClick={(event: any) => {
                  event.stopPropagation();
                  if (handleJobEdit) {
                    handleJobEdit(job?._id);
                  }
                  handleJobMenuClose();
                }}
              >
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={(event: any) => {
                  event.stopPropagation();
                  if (handleJobArchive) {
                    handleJobArchive(job?._id);
                  }
                  handleJobMenuClose();
                }}
              >
                <ListItemText>Archive</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={(event: any) => {
                  event.stopPropagation();
                  if (handleJobDelete) {
                    handleJobDelete(job?._id);
                  }
                  handleJobMenuClose();
                }}
              >
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Stack>
      <Divider></Divider>
      <Stack>
        {isHomeFeatured && (
          <Typography
            variant="body2"
            color={theme?.palette?.primary?.textMid}
            fontWeight={500}
            mt={1}
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              textOverflow: "ellipsis",

              "-webkit-line-clamp": "2",
              "-webkit-box-orient": "vertical",
              height: isFullWidth ? "auto" : "47px",
            }}
          >
            {parse(job?.description || "")}
          </Typography>
        )}
        <Box my={2} display={"flex"} gap={1} flexWrap={"wrap"}>
          <Chip
            icon={<Box component={"img"} src={ExperienceIcon} />}
            label={`${job?.experience_low}-${job?.experience_high} years`}
            variant="outlined"
            sx={{
              "& .MuiChip-icon": {
                marginLeft: "10px",
              },
              "& .MuiChip-label": {
                color: theme?.palette?.primary?.textBtn,
                fontWeight: 600,
              },
            }}
          />
          <Chip
            icon={<Box component={"img"} src={ClockIcon} />}
            label={job?.type?.name || job?.type?.type}
            variant="outlined"
            sx={{
              "& .MuiChip-icon": {
                marginLeft: "10px",
              },
              "& .MuiChip-label": {
                color: theme?.palette?.primary?.textBtn,
                fontWeight: 600,
              },
            }}
          />
          <Chip
            icon={<Box component={"img"} src={RupeeIcon} />}
            label={`${job?.salary_low} - ${job?.salary_high} LPA`}
            variant="outlined"
            sx={{
              "& .MuiChip-icon": {
                marginLeft: "10px",
              },
              "& .MuiChip-label": {
                color: theme?.palette?.primary?.textBtn,
                fontWeight: 600,
              },
            }}
          />
          {hasLocationChip && (
            <Chip
              icon={<Box component={"img"} src={LocationIcon} />}
              label={
                <>
                  <Tooltip
                    title={job?.job_location
                      ?.map((item: any) => item.name)
                      .join(", ")}
                    disableHoverListener={job?.job_location?.length <= 1}
                  >
                    <Typography
                      variant="body2"
                      color={theme?.palette?.primary?.textMid}
                      fontWeight={500}
                      component={"span"}
                    >
                      {job?.job_location[0]?.name ||
                        job?.job_location[0]?.city ||
                        "-"}
                    </Typography>
                  </Tooltip>
                  {job?.job_location?.length > 1 && (
                    <Tooltip
                      title={job?.job_location
                        ?.map((item: any) => item.name)
                        .join(", ")}
                      disableHoverListener={job?.job_location?.length <= 1}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={600}
                        component={"span"}
                        color={
                          branding
                            ? branding
                            : theme?.palette?.primary?.iconOrange
                        }
                      >
                        {" "}
                        +{job?.job_location?.length - 1} more
                      </Typography>
                    </Tooltip>
                  )}
                </>
              }
              variant="outlined"
              sx={{
                "& .MuiChip-icon": {
                  marginLeft: "10px",
                },
                "& .MuiChip-label": {
                  color: theme?.palette?.primary?.textBtn,
                  fontWeight: 500,
                },
              }}
            />
          )}
        </Box>
        {isHomeFeatured && (
          <Box mb={hasLocationChip ? 1 : 0}>
            <Typography
              component={"span"}
              variant="body2"
              color={theme?.palette?.primary?.textMid}
              fontWeight={500}
            >
              Skills:{" "}
            </Typography>
            <Typography
              variant="body2"
              fontWeight={500}
              component={"span"}
              color={theme?.palette?.primary?.textBtn}
            >
              {getSkills(job?.skills)}
            </Typography>
          </Box>
        )}
        {!hasLocationChip && (
          <Box mb={1}>
            <Typography
              component={"span"}
              variant="body2"
              color={theme?.palette?.primary?.textMid}
              fontWeight={500}
            >
              Applicant:{" "}
            </Typography>
            <Typography
              variant="body2"
              fontWeight={500}
              component={"span"}
              color={theme?.palette?.primary?.textBtn}
            >
              {job?.applicants || job?.applications_count || 0}
            </Typography>
          </Box>
        )}
        <Divider></Divider>
        <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
          {hasLocationChip && (
            <Box mb={1} display={"flex"} gap={2}>
              <Box>
                <Typography
                  component={"span"}
                  variant="body2"
                  color={theme?.palette?.primary?.textMid}
                  fontWeight={500}
                >
                  Applicant:{" "}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight={500}
                  component={"span"}
                  color={theme?.palette?.primary?.textBtn}
                >
                  {job?.applicants || job?.applications_count || 0}
                </Typography>
              </Box>
              {isAppliedJob && (
                <Box>
                  <Typography
                    component={"span"}
                    variant="body2"
                    color={theme?.palette?.primary?.textMid}
                    fontWeight={500}
                  >
                    Hired:{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    component={"span"}
                    color={theme?.palette?.primary?.textBtn}
                  >
                    {job?.hired_count}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
          {!hasLocationChip && (
            <Box display={"flex"} gap={1}>
              <Box component={"img"} src={LocationIcon} />
              <Tooltip
                title={job?.job_location
                  ?.map((item: any) => item.name)
                  .join(", ")}
                disableHoverListener={job?.job_location?.length <= 1}
              >
                <Typography
                  variant="body2"
                  color={theme?.palette?.primary?.textMid}
                  fontWeight={500}
                  component={"span"}
                >
                  {job?.job_location[0]?.name ||
                    job?.job_location[0]?.city ||
                    "-"}
                </Typography>
              </Tooltip>
              {job?.job_location?.length > 1 && (
                <Tooltip
                  title={job?.job_location
                    ?.map((item: any) => item.name)
                    .join(", ")}
                  disableHoverListener={job?.job_location?.length <= 1}
                >
                  <Box display={"flex"} gap={0.5}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      component={"span"}
                      color={
                        branding
                          ? branding
                          : theme?.palette?.primary?.iconOrange
                      }
                    >
                      +{job?.job_location?.length - 1}
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      component={"span"}
                      color={
                        branding
                          ? branding
                          : theme?.palette?.primary?.iconOrange
                      }
                    >
                      more
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            </Box>
          )}
          <Box>
            {isAppliedJob ? (
              <></>
            ) : (
              <Typography
                variant="body2"
                color={theme?.palette?.primary?.textMid}
                fontWeight={500}
                component={"span"}
              >
                {getPostedDateTime(job?.created_at, "", job.status)}
              </Typography>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default JobCard;

function getSkills(skillList: any): string {
  return skillList?.map((skill: any) => skill.name).join(",");
}

function getPostedDateTime(
  postedAt: string,
  type: string = "",
  status: string = ""
): string {
  const postedDate = dayjs(postedAt);
  const currentDate = dayjs();

  // Calculate the difference in days
  const diffDays = currentDate.diff(postedDate, "day");
  const verb = status === "draft" ? "Saved" : "Posted";
  if (diffDays === 0) {
    return type === "applied" ? "Today" : `${verb} today`;
  } else if (diffDays === 1) {
    return type === "applied" ? "Yesterday" : `${verb} yesterday`;
  } else if (diffDays < 1) {
    return type === "applied" ? "Now" : `${verb} now`;
  } else {
    // Return the date in the specified format
    return postedDate.format("MMMM DD, YYYY");
  }
}
